/* CSS Resets */
*,
*::before,
*::after{
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html{
  font-size: 62.5%;
  box-sizing: border-box;
}

body{
  font-weight: 400;
  line-height: 1.6;
  font-family: Quicksand, sans-serif;
  
}
.MuiTab-wrapper{
  font-size: 1.5rem;
}


a:link,
a:visited{
  text-decoration: none;
  color: #fff;
  opacity: .7;
  font-weight: bold;
  transition: all .3s ease;
}
a:hover{
  opacity: 1;
  cursor: pointer;
}

li{
  list-style: none;
}
main{
  font-size: 1.6rem;
}
h2{
  text-align: center;
  font-size: 3rem;
  text-transform: capitalize;
  color: #22ACE3;
  margin: 2rem 2rem 3rem 2rem;
}
h4{
  color: #F98908;
  font-weight: bold;
}
section{
  padding: 8rem 0;
}
.MuiDrawer-paper{
  font-size: 1.7rem;
}
.jumbo{
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 2.7rem;
  width: 100%;
}
.jumbo__content{
  padding-left: 10rem;
}
.jumbo__content--heading{
  color: #fff;
  font-family: 'Raleway';
  font-weight: 100;
  margin-bottom: 2rem;
  width: 70%;
}
.jumbo__content button{
  color: #fff;
  font-size: 1.6rem;
}
.services{
  background: #eee;
  padding: 10rem 3rem;
  font-size: 1.5rem;
}
.services__body{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: top;
  flex-wrap: wrap;
}
.services__card{
  margin-top: 1rem;
  width: 30rem;
  background: #fff;
  padding: 2rem;
  box-shadow: 1px 2px 12px 3px rgba(0, 0, 0, .2);
  text-align: center;
  border-radius: 5px;
}
.services__card--img{
  margin-bottom: 2rem;
  
}
.services__card--img img{
  width: 29%;
}
.services__card--title{
  color: #22ACE3;
  
}
.reduce{
  width: 24%!important;
}
.profiles{
  background: #eee;
  padding: 15rem 5rem 5rem;
  font-size: 1.5rem;
}
.profiles__body{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.profiles__content{
  width: 60rem;
  padding: 1rem;
}
.profiles__content p{
  font-size: 2rem;
  color: #333;
}
.profiles__img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.profiles__img img{
  width: 70%;
  
}
.works{
  padding: 15rem 5rem 5rem;
  font-size: 1.5rem;
}
.works__body{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.works__content{
  width: 60rem;
  padding: 1rem;
}
.works__content p{
  font-size: 2rem;
  color: #333;
}
.works__img{
  display: flex;
  justify-content: center;
  align-items: center;
}
.works__img img{
  width: 70%;
  
}

.usp{
  padding: 15rem 5rem 5rem;
}
.usp p{
  font-size: 2rem;
}
.usp__1, .usp__2{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10rem;
}
.usp__1 img, .usp__2 img{
  width: 90%;
}
.usp__1--content{
  width: 60rem;
  padding: 1rem;
}
.usp__2--content{
  width: 60rem;
  padding: 1rem;
}

.footer{
  background-color: #0a4a63;
  color: #fff;
  text-align: center;
  font-size: 1.6rem;
  padding-top: 3rem;
}
.footer__secondary{
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: top;
  flex-wrap: wrap;
  padding: 2rem;
  line-height: 4rem;
}

.footer__primary{
  padding: 2rem;
  background-color: #043c52;
}
.social{
  display: flex;
  justify-content: space-evenly;
}
.social a{
  color: #F98908;
  font-size: 2.1rem;
}

@media only screen and (max-width: 800px){
  html{
    font-size: 50%;
  }
}

@media only screen and (max-width: 600px){
  html{
    font-size: 37.5%;
  }
}