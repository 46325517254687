.dashboard{
  margin-top: 6.4rem;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 15% 1fr 1fr 1fr;
  grid-template-rows: 10% 30% auto;
  grid-template-areas: 
    "sidebar header header header" 
    "sidebar section section section"
    "sidebar main main main";
}

.dashboard__sidebar{
  grid-area: sidebar;
  background-color: #043c52;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 2.5rem;
  flex-direction: column;
}

.dashboard__sidebar img{
  width: 30%;
  border: 2px solid #fff;
  border-radius: 50%;
}

.dashboard__sidebar--content{
  font-size: 1.6rem;
  text-align: center;
}
.dashboard__sidebar--content p{
  color: #fff;
  font-size: 1.1rem;
}
.dashboard__sidebar--menu{
  margin-top: 5rem;
  font-size: 1.4rem;
  background-color: #033447;
  align-self: stretch;
  text-align: center;
}
.dashboard__sidebar--menu li{
  padding: 1rem;
  border-bottom: 1px solid #111;
}
.dashboard__sidebar--menu li a{
  font-weight: 400;
  opacity: .7;
}
.dashboard__sidebar--menu li.active{
  opacity: 1;
  background-color: #022533;
}
.dashboard__sidebar--menu li.active a{
  color: #F98908;
  font-weight: 800;
}


/* ------------------- Header ----------------- */
.dashboard__header{
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
  width: 100%;
}
.dashboard__header--search{
  width: 100%;
}
.dashboard__header input{
  border: none;
  width: 30%;
  padding: 1rem;
  border-radius: 10rem;
  background: #ddd;
  outline: none;
}

.dashboard__header--notify{
  width: 8%;
  position: relative;
  display: flex;
  justify-content: space-evenly;
}
.dashboard__header--notify i, .dashboard__header--notify span{
  cursor: pointer;
}

.dashboard__header span{
  position: absolute;
  left: 30%;
  top: 50%;
  font-weight: bold;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  padding: 0 .7rem;
}


/* ------------------- Section ----------------- */

.dashboard__section{
  grid-area: section;
  background-color: #eee;
}
.dashboard__section--body{
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: top;
  flex-wrap: wrap;
  font-size: 1.4rem;
}
.dashboard__section--card{
  margin-top: 1rem;
  width: 30rem;
  background: #fff;
  padding: 2rem;
  box-shadow: 1px 2px 12px 3px rgba(0, 0, 0, .2);
  text-align: center;
  border-radius: 5px;
}
.dashboard__section--stats{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard__section--stats li{
  list-style: disc;
  color: #0a4a63;
}
.dashboard__section--stats img{
  width: 30%;
}
/* ------------------- Section ----------------- */
.dashboard__main{
  grid-area: main;
  background-color: #ddd;
  padding: 5rem 8rem;
  font-size: 1.4rem;
}
.dashboard__content{
  background: #fff;
  box-shadow: 1px 2px 12px 3px rgba(0, 0, 0, .2);
  padding: 2rem;
}
.dashboard__content--item{
  border-bottom: 1px solid #ccc;
  padding: 2rem;
}
.dashboard__content:not(:nth-last-child(2)){
  border-bottom: none;
}
.dashboard__content--meta{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.dashboard__content--badge{
  background: #999;
  font-weight: bold;
  margin-right: .5rem;
  color: #fff;
  padding: .7rem 1rem;
  font-size: 1rem;
  border-radius: 50%;
} 
.dashboard__content--price{
  font-size: 2rem;
  margin-right: 10rem;
  font-weight: bold;
  color: #555;
}
.dashboard__content--time{
  font-style: italic;
  margin: .8rem 0;
}

@media only screen and (min-width:600px){
  /* .dashboard{
    grid-template-areas: 
    "sidebar header header header" 
    "sidebar section section section"
    "sidebar main main main";
  } */
}